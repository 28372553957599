// Reference: https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
import { urls } from "api/resources/users/urls"
import axios from "axios"
import { clearAuthInfo, getAuthInfo, saveAuthInfo } from "utils/auth"
import { Platforms } from "utils/constants"

// Not using api/axios here because of cyclical imports
export function refreshToken(): Promise<void> {
  return new Promise((resolve, reject) => {
    const authInfo = getAuthInfo()
    if (!authInfo) {
      reject(new Error("Access token not found"))
      window.location.reload()
      return
    }

    axios
      .post<any>(
        `${import.meta.env.VITE_API_ENDPOINT}/${urls.refreshToken()}`,
        {
          refreshToken: authInfo.refreshToken,
        },
        {
          headers: {
            Authorization: null,
          },
        }
      )
      .then(res => {
        /**
         * We're using res.data.data here because this is not our Axios instance. See the import
         */
        const newAuthInfo = {
          ...authInfo,
          accessToken: res.data.data.access_token,
          refreshToken: res.data.data.refresh_token,
          platform: Platforms.student,
        }
        saveAuthInfo(newAuthInfo)
        window.location.reload()
        resolve()
      })
      .catch(error => {
        console.info("> Refresh token expired. Logging out.")
        clearAuthInfo()
        window.location.href = "/"
        reject(error)
      })
  })
}

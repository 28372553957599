import { Button, toast } from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import {
  ApplicationDeclaration,
  CheckListType,
  ReviewStatus,
} from "api/resources/applications/types"
import { APIError } from "api/utils"
import { useContext } from "react"
import { mapErrors } from "utils/helpers"
import { ApplicationContext } from "views/application/context/ApplicationContext"

const ERROR_MSG = "Required to be filled or uncheck the box"

const AskForChangesButton = ({
  size,
}: {
  size?: "sm" | "md" | "lg" | undefined
}) => {
  // const navigate = useNavigate()

  const {
    educationCheckList,
    setEducationCheckList,
    setPersonalCheckList,
    personalCheckList,
    applicationId,
    applicantDetails,
    generalComments,
    employmentCheckList,
    setEmploymentCheckList,
    refetchApplication,
    localDeclarations,
    setLocalDeclarations,
  } = useContext(ApplicationContext)

  const handleSuccess = () => {
    refetchApplication()
    // Uncomment if localForage logic is required
    // localForage
    //   .removeItem(applicationId)
    //   .then(() => {
    //     setSubmitLoading(false);
    //     refetchApplication();
    //     // navigate(-1);
    //   })
    //   .catch(err => {
    //     toast.error(err);
    //     console.error(err);
    //     setSubmitLoading(false);
    //   });
  }

  const bulkCreateReview = useMutation({
    mutationFn: ({ checkList }: { checkList: CheckListType }) =>
      api.applications.review.bulkCreate({
        urlParams: {
          reviewId: applicantDetails?.review?.id || "",
        },
        data: {
          reviewChangeData: [
            ...(checkList.education ? checkList.education : []),
            ...(checkList.personal ? checkList.personal : []),
            ...(checkList.employment ? checkList.employment : []),
            ...(generalComments.length > 0
              ? generalComments.map(v => v.comment)
              : []),
          ],
        },
      }),
    onError: err => {
      if (err instanceof APIError) mapErrors(err)
    },
    onSuccess: () => {
      if (localDeclarations.length === 0) {
        handleSuccess()
      }
    },
  })

  const bulkCreateDeclaration = useMutation({
    mutationFn: ({
      localDeclarations,
    }: {
      localDeclarations: ApplicationDeclaration[]
    }) =>
      api.applications.review.bulkCreateDeclaration({
        urlParams: {
          applicationId,
        },
        data: {
          applicationDeclarationsData: localDeclarations.map(i => ({
            declaration: i.declaration,
            ...(i.requiredDocuments && {
              requiredDocuments: i.requiredDocuments,
            }),
          })),
        },
      }),
    onError: err => {
      if (err instanceof APIError) mapErrors(err)
    },
    onSuccess: () => {
      setLocalDeclarations([])
      handleSuccess()
    },
  })

  const updateReviewStatus = useMutation({
    mutationFn: () => {
      return api.applications.review.updateStatus({
        params: {
          review_id: applicantDetails?.review?.id || "",
        },
        data: { status: ReviewStatus.CHANGES_REQUESTED },
      })
    },
    onSuccess: () => {
      refetchApplication()
    },
    onError: err => {
      if (err instanceof APIError) {
        mapErrors(err)
      }
    },
  })
  const onSubmitComment = async () => {
    let hasError = false

    const localDeclarationsError = localDeclarations.filter(
      item =>
        !item.declaration ||
        (item.requiredDocuments && item.requiredDocuments.length === 0)
    )

    educationCheckList.forEach(({ comment, error }) => {
      if (error || !comment) hasError = true
    })
    personalCheckList.forEach(({ comment, error }) => {
      if (error || !comment) hasError = true
    })
    employmentCheckList.forEach(({ comment, error }) => {
      if (error || !comment) hasError = true
    })
    if (localDeclarationsError.length > 0) {
      toast.error(
        "Discard incomplete declaration or fill them out completely before submitting."
      )
      return
    }

    if (hasError) {
      setEducationCheckList(prevState =>
        prevState.map(i =>
          i.error || !i.comment ? { ...i, error: ERROR_MSG } : i
        )
      )
      setPersonalCheckList(prevState =>
        prevState.map(i =>
          i.error || !i.comment ? { ...i, error: ERROR_MSG } : i
        )
      )
      setEmploymentCheckList(prevState =>
        prevState.map(i =>
          i.error || !i.comment ? { ...i, error: ERROR_MSG } : i
        )
      )
      toast.error("Recheck all the comments")
      return
    }
    if (localDeclarations.length > 0) {
      await bulkCreateDeclaration.mutateAsync({
        localDeclarations,
      })
    }

    if (generalComments.length > 0) {
      await bulkCreateReview.mutateAsync({ checkList: {} })
    }

    if (
      educationCheckList.length === 0 &&
      personalCheckList.length === 0 &&
      employmentCheckList.length === 0
    ) {
      await updateReviewStatus.mutateAsync()
    } else {
      await bulkCreateReview.mutateAsync({
        checkList: {
          education: educationCheckList,
          personal: personalCheckList,
          employment: employmentCheckList,
        },
      })
    }

    // localForage
    //   .getItem<CheckListType>(applicationId)
    //   .then(item => {
    //     if (item) onSubmit(item)
    //     else if (generalComments.length > 0) {
    //       onSubmit({})
    //     } else {
    //       setSubmitLoading(false)
    //     }
    //   })
    //   .catch(err => {
    //     console.error(err)
    //     setSubmitLoading(false)
    //   })
  }
  return (
    <Button
      loading={bulkCreateReview.isLoading || bulkCreateDeclaration.isLoading}
      size={size}
      onClick={onSubmitComment}
    >
      Ask For Changes
    </Button>
  )
}

export default AskForChangesButton
